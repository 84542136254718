<template>
  <label :class="classes">
    <span v-if="label" class="label">
      {{ label }}<span v-if="isRequired" class="required">*</span>
      <span v-if="labelRight" class="label--right">{{ labelRight }}</span>
    </span>

    <slot name="pills" />

    <span class="row">
      <span class="field__wrap">
        <input
          class="field"
          v-maska="'T.T'"
          type="text"
          inputmode="decimal"
          maxlength="10"
          data-maska-tokens="T:[0-9]:multiple"
          :name="name"
          :value="props.value"
          :required="props.isRequired"
          :placeholder="props.placeholder || ''"
          :style="{ width: `${width}px` }"
          @blur="onBlur"
          @input="onInput"
        />

        <span v-if="props.currency" class="mask">{{ props.currency }}</span>
      </span>

      <slot></slot>
    </span>

    <atomic-hint v-if="props.hint" v-bind="props.hint" />
  </label>
</template>

<script setup lang="ts">
  import type { IFieldHint } from '@skeleton/types';

  interface IProps {
    name: string;
    currency?: string;
    value?: number | string;
    min?: number;
    max?: number;
    label: string;
    labelRight?: string;
    placeholder?: string;
    isRequired?: boolean;
    hint?: IFieldHint;
    isBigger?: boolean;
    defaultValue?: number;
    width?: number;
  }

  const props = withDefaults(defineProps<IProps>(), {
    value: 0,
    min: undefined,
    max: undefined,
    placeholder: '',
    isRequired: false,
    isBigger: false,
    currency: undefined,
    hint: undefined,
    labelRight: undefined,
    defaultValue: undefined,
  });

  const emit = defineEmits(['blur', 'update:value', 'input']);

  const onInput = (e: Event): void => {
    const target = e.target as HTMLInputElement;

    target.value = target.value.replace(/^0[0-9]/, (match: string) => match.slice(1));
    emit('input', target.value);
    emit('update:value', target.value);
  };

  const onBlur = (e: Event): void => {
    const target = e.target as HTMLInputElement;
    let value = target.value;

    if (!value || +value < (props.min ?? 0)) {
      value = props.min ? props.min.toString() : props.defaultValue?.toString() || '0';
    }

    if (+value > (props.max ?? Infinity)) {
      value = props.max ? props.max.toString() : props.defaultValue?.toString() || '0';
    }

    target.value = value;
    emit('input', value);
    emit('update:value', value);
    emit('blur', value);
  };

  const classes = computed(() => [
    'input-number',
    { 'has-error': props.hint?.variant === 'error' },
    { 'is-bigger': props.isBigger },
  ]);
</script>

<style src="~/assets/styles/components/form/input/number.scss" lang="scss" />
